body{
  padding-bottom: 25px;
}
#btn-submit{
  background-color: #005018;
  border-color: #005018;
}
a {
  color: #005018;
  text-decoration:underline;
  transition: all;
}
a:hover{
  color: #013912;
}
.form-check{
  display: flex;
  align-items: center;
  text-align: left;
  justify-content: start;
  padding: 0;
  margin-bottom: 5px;
}
.form-check-label{
  font-size: 12px;
  display: inline;
  padding: 0;
}
.form-check-input{
  position: relative;
  margin: 0;
  margin-right: 5px;
}
.required{
  color: red;
}
.helpCode{
  display: inline-block;
  font-size: 12px;
  color: #7a7a7a;
}